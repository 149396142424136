import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateDepartureMutation,
  useDeleteDepartureMutation,
  useGetDepartureByPackageIdQuery,
  useUpdateDepartureInfoMutation,
} from "../../redux/slice/departure/departureApiSlice";
import { RoundedSubmitButton } from "../Core/Button";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  Alert,
  Spin,
  TreeSelect,
  Select,
  DatePicker,
  InputNumber,
  Tabs,
  Typography,
  Table,
  Radio,
  Modal,
  Space,
  Popconfirm,
  Button,
  message,
} from "antd";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment/moment";
import SpeedoMeter from "../SpeedoMeter";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const CollectionCreateForm = ({ open, onCreate, onCancel, data, loading }) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  if (data) {
    setFieldsValue({
      departure_date: moment(data.departure_date),
      departure_cost: data.departure_cost,
      departure_note: data.departure_note,
      departure_status: data.departure_status,
      package_id: data.package_id,
      id: data.id,
    });
  }

  return (
    <Modal
      open={open}
      title="Edit Departure Date"
      okText="Save Changes"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        console.log("updated clicked");
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCancel();
            onCreate({
              formData: {
                ...values,
                departure_date: moment(values.departure_date).format("YYYY-MM-DD"),
              },
              id: values.id,
            });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <div>
          <Form.Item
            name="departure_date"
            label="Date"
            rules={[
              {
                required: true,
                message: "Date is Required!",
              },
            ]}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              disabledDate={(current) => current && current < moment().endOf("day")}
            />
          </Form.Item>
          <Form.Item
            name="departure_cost"
            label="Cost"
            rules={[
              {
                required: true,
                message: "Price is Required!",
              },
            ]}
          >
            <InputNumber placeholder="Depature cost" />
          </Form.Item>
          <Form.Item name="departure_note" label="Note">
            <Input placeholder="Depature note" />
          </Form.Item>
          <Form.Item
            name="departure_status"
            label="Cost"
            rules={[
              {
                required: true,
                message: "Depature Status is required!",
              },
            ]}
          >
            <Select placeholder="Status">
              <Option value="guaranteed">Guaranteed</Option>
              <Option value="available">Available</Option>
              <Option value="limited">Limited</Option>
              <Option value="closed">Closed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="id" label="id" hidden>
            <InputNumber />
          </Form.Item>
          <Form.Item name="package_id" label="id" hidden>
            <InputNumber />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

const DepartureForm = ({
  // activeDepartures,
  packageId,
  group_default_price,
  isAddForm,
  isUpdateForm,
  hasDeparture
}) => {
  let params = useParams();
  const [onDelete] = useDeleteDepartureMutation("deleteEntityById");

  const [
    createEntity,
    {
      data: createdDepartureData,
      isLoading: creatingDeparture,
      isError: createDepartureError,
      isSuccess: createDepartureSuccess,
      error: errorCreate,
    },
  ] = useCreateDepartureMutation("createDeparture");

  const [
    updateEntityInfo,
    {
      data: updatedDeparture,
      isLoading: updating,
      isError: updateDepartureError,
      isSuccess: updateSuccess,
      error,
    },
  ] = useUpdateDepartureInfoMutation("updateDeparture");

  const {
    data: departuresByPackageId,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
  } = useGetDepartureByPackageIdQuery(params.id);

  let data = {
    data: undefined,
    loading: updating || loadingById || creatingDeparture,
    error: updateError || updateDepartureError || createDepartureError,
    dataById: successById && departuresByPackageId,
  };

  const selectedForm = {
    data: undefined,
    loading: updating || creatingDeparture,
    error: createDepartureError ? errorCreate?.data?.errors : error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  let navigate = useNavigate();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const [tdata, setTData] = useState();
  const [editingKey, setEditingKey] = useState("");
  const [selectedDay, setSelectedDay] = useState();
  const [open, setOpen] = useState(false);
  const [popUpData, setPopUpData] = useState();
  const [selectedItems, setSelectedItems] = useState([]);

  const loading = selectedForm.loading || data.loading;

  useEffect(() => {
    setTData(
      departuresByPackageId?.map((itm) => {
        return {
          ...itm,
          key: itm.id,
          //departure_date: moment(itm.departure_date).format("DD, MMM, YYYY"),
        };
      })
    );
  }, [departuresByPackageId]);

  const clearForm = () => {
    form.resetFields();
  };

  if (selectedForm?.loadedRecently && isAddForm) {
    clearForm();
  }

  const cancel = () => {
    setEditingKey("");
  };

  const handleDelete = (key) => {
    onDelete([key]);
    const newData = tdata.filter((item) => item.key !== key);
    setTData(newData);
  };

  const handleEdit = (key) => {
    setPopUpData(key);
    setOpen(true);
  };

  const columns = [
    {
      title: "Dates",
      dataIndex: "departure_date",
      width: "25%",
    },
    {
      title: "Cost",
      dataIndex: "departure_cost",
      width: "15%",
    },
    {
      title: "Note",
      dataIndex: "departure_note",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "departure_status",
      width: "20%",
    },

    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        tdata.length >= 1 ? (
          <Space size="middle">
            <a className="btn btn-sm btn-primary" onClick={() => handleEdit(record)}>
              <i className="bi-pencil-square" />
              Edit
            </a>
          </Space>
        ) : null,
    },
  ];

  const departures = selectedDay?.map((itm) => {
    return {
      departure_date: moment(itm).format("YYYY-MM-DD"),
      departure_cost: form.getFieldValue("cost"),
      departure_note: form.getFieldValue("note"),
      departure_status: form.getFieldValue("status"),
    };
  });

  if (isUpdateForm) {
    setFieldsValue({
      package_id: params.id,
    });
  }

  form.setFieldValue("cost", group_default_price);

  const DepartureAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity({
          ...values,
          departures,
        })
          .unwrap()
          .then((data) => {
            console.log(data);
            setSelectedDay();
            message.success("Departure Added Successfully");
          })
          .catch();
      } else if (action === "addandexit") {
        createEntity({
          ...values,
          departures,
        })
          .unwrap()
          .then(() => message.success("Departure Added Successfully"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...values,
            departures,
          },
          id: params.id,
        })
          .unwrap()
          .then(() => message.success("Departure Updated Successfully"))
          .catch();
      }
    });
  };

  const addNewEntry = (e) => {
    DepartureAction(e, "add");
  };


  let disabledDays = tdata?.map(
    (itm) => new Date(moment(itm.departure_date).format("YYYY, MM, DD"))
  );

  const OperationsSlot = {
    left: (
      <div className="inner-action-bar header">
        <div className="left-content">
          <h2>Pricing & Availability</h2>
          {hasDeparture ? <SpeedoMeter score={15} totalScore={15} /> : <SpeedoMeter score={0} totalScore={15} /> }          
        </div>
      </div>
    ),
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedItems(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedItems,
    onChange: onSelectChange,
  };

  const handleBulkDelete = () => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure, you want to delete?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        onDelete(selectedItems);
        setTData(tdata.filter((item) => !selectedItems.includes(item.id)));
      },
    });
  };

  const tableSources =
    tdata &&
    tdata.sort((a, b) => {
      return Number(new Date(a.departure_date)) - Number(new Date(b.departure_date));
    });

  const tableasyncdata = tableSources?.map((itm) => {
    return {
      ...itm,
      departure_date: moment(itm.departure_date).format("DD MMM, YYYY"),
    };
  });

  return (
    <div className="common-module">
      {selectedForm?.error?.errors && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}

      <Tabs defaultActiveKey="1" tabBarExtraContent={OperationsSlot} className="flamingo-tabs">
        <Tabs.TabPane tab="All Dates" key="1">
          <Form form={form} component={false} initialValues={{ cost: group_default_price }}>
            <CollectionCreateForm
              open={open}
              loading={loading}
              data={popUpData}
              onCreate={updateEntityInfo}
              onCancel={() => {
                setOpen(false);
              }}
            />
            <div className="common-module bg-white data-table mb-0">
              {selectedItems.length >= 1 && (
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <Button
                    type="primary"
                    danger
                    shape="round"
                    style={{
                      fontWeight: 500,
                      backgroundColor: "#dc3545",
                      fontFamily: "var(--flm-btn-font-family)",
                      border: 0,
                      fontSize: "13px",
                    }}
                    onClick={handleBulkDelete}
                  >
                    <i
                      className="bi-trash"
                      style={{
                        lineHeight: 0,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    ></i>
                    Delete
                  </Button>
                </div>
              )}
              <Table
                loading={loading}
                dataSource={tableasyncdata && tableasyncdata}
                columns={columns}
                rowSelection={rowSelection}
                pagination={{
                  onChange: cancel,
                }}
              />
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Add New Departure" key="2">
          <Form
            form={form}
            size="large"
            layout="vertical"
            className="custom-form"
            wrapperCol={{
              flex: 1,
            }}
          >
            <Spin spinning={loading} delay={500}>
              <div className="common-module mb-0" hidden>
                <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                  <Col className="gutter-row" xs={24} sm={12} xl={12}>
                    <Form.Item
                      label="Package"
                      name="package_id"
                      initialValue={params.id}
                      rules={[
                        {
                          required: true,
                          message: "Package is required",
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="module-container package-departure">
                <div className="module-container-left">
                  <h6 className="module-title">
                    Selected Dates ({selectedDay?.length > 0 ? selectedDay?.length : 0})
                  </h6>
                  <div className="date-list">
                    <ul>
                      {selectedDay?.map((itm, idx) => {
                        return <li key={idx}><span>{moment(itm).format("DD MMM, YYYY")}</span></li>;
                      })}
                    </ul>
                  </div>
                </div>
                <div className="module-container-right">
                <DayPicker
                      numberOfMonths={2}
                      showWeekNumber
                      showOutsideDays
                      mode="multiple"
                      onSelect={setSelectedDay}
                      selected={selectedDay}
                      fromDate={new Date()}
                      disabled={disabledDays}
                    />
                    <Form
                      form={form}
                      size="large"
                      layout="vertical"
                      className="custom-form"
                      wrapperCol={{
                        flex: 1,
                      }}
                    >
                      {selectedForm?.error?.errors && (
                        <Alert
                          className="custom-alert"
                          message="Error"
                          showIcon
                          description={[
                            <ul>
                              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                                return <li key={idx}>{itm}</li>;
                              })}
                            </ul>,
                          ]}
                          type="error"
                          closable
                        />
                      )}

                      <Spin spinning={loading} delay={500}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                          <Col className="gutter-row" xs={24} xl={16}>
                            <Form.Item
                              name="cost"
                              label="Depature cost"
                              rules={[
                                {
                                  required: true,
                                  message: "Departure cost is required",
                                },
                              ]}
                            >
                              <InputNumber
                                value={group_default_price}
                                placeholder="Depature cost"
                              />
                            </Form.Item>
                            <Form.Item name="note" label="Note">
                              <Input placeholder="Depature note" />
                            </Form.Item>
                            <Form.Item
                              name="status"
                              label="Depature Status"
                              initialValue="guaranteed"
                              rules={[
                                {
                                  required: true,
                                  message: "Depature Status is required",
                                },
                              ]}
                            >
                              <Select placeholder="Depature Status">
                                <Option value="guaranteed" selected>
                                  Guaranteed
                                </Option>
                                <Option value="available">Available</Option>
                                <Option value="limited">Limited</Option>
                                <Option value="closed">Closed</Option>
                              </Select>
                            </Form.Item>
                            <RoundedSubmitButton
                              disabled={loading}
                              className="btn btn-primary"
                              id="add_new"
                              onClick={addNewEntry}
                            >
                              Save Changes
                            </RoundedSubmitButton>
                          </Col>
                        </Row>
                      </Spin>
                    </Form>
                </div>
              </div>
            </Spin>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default DepartureForm;
