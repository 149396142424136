import { Routes, Route } from "react-router-dom";
import Login from "./Containers/Login";
import RequireAuth from "./Components/RequireAuth";

import Dashboard from "./Containers/Dashboard";
import User from "./Containers/User/List";
import UserAdd from "./Containers/User/Add";
import UserUpdate from "./Containers/User/Update";

import SettingAdd from "./Containers/Settings/Add";
import SettingUpdate from "./Containers/Settings/Update";
import SettingsList from "./Containers/Settings/List";

import CategoryList from "./Containers/Category/List";
import CategoryAdd from "./Containers/Category/Add";
import CategoryUpdate from "./Containers/Category/Update";
import DeletedCategory from "./Containers/Category/DeletedList";
import CategoryType from "./Containers/Category/CategoryType";
import CategoryTypeAdd from "./Containers/Category/CategoryTypeAdd";
import CategoryTypeUpdate from "./Containers/Category/CategoryTypeUpdate";
import DeletedCategoryType from "./Containers/Category/DeletedCategoryType";

import AlbumAdd from "./Containers/Album/Add";
import AlbumList from "./Containers/Album/List";
import AlbumUpdate from "./Containers/Album/Update";
import DeletedAlbum from "./Containers/Album/DeletedList";

import PageList from "./Containers/Page/List";
import PageUpdate from "./Containers/Page/Update";
import PageAdd from "./Containers/Page/Add";
import DeletedPage from "./Containers/Page/DeletedList";

import MenuAdd from "./Containers/Menu/Add";
import MenuUpdate from "./Containers/Menu/Update";
import MenuOrder from "./Containers/Menu/Order";
import MenuList from "./Containers/Menu/List";
import DeletedMenu from "./Containers/Menu/DeletedList";

import MenuTypeAdd from "./Containers/Menu/MenuTypeAdd";
import MenuTypeUpdate from "./Containers/Menu/MenuTypeUpdate";
import MenuTypeList from "./Containers/Menu/MenuType";
import DeletedMenuType from "./Containers/Menu/DeletedMenuType";

import TeamAdd from "./Containers/Team/Add";
import TeamList from "./Containers/Team/List";
import TeamUpdate from "./Containers/Team/Update";
import DeletedTeam from "./Containers/Team/DeletedList";

import TestimonialList from "./Containers/Testimonial/List";
import TestimonialAdd from "./Containers/Testimonial/Add";
import TestimonialUpdate from "./Containers/Testimonial/Update";
import DeletedTestimonial from "./Containers/Testimonial/DeletedList";

import FaqsList from "./Containers/Faqs/List";
import FaqsAdd from "./Containers/Faqs/Add";
import FaqsUpdate from "./Containers/Faqs/Update";
import DeletedFaqs from "./Containers/Faqs/DeletedList";

import AuthorList from "./Containers/Author/List";
import AuthorAdd from "./Containers/Author/Add";
import AuthorUpdate from "./Containers/Author/Update";
import DeletedAuthor from "./Containers/Author/DeletedList";

import BlogList from "./Containers/Blog/List";
import BlogAdd from "./Containers/Blog/Add";
import BlogUpdate from "./Containers/Blog/Update";
import DeletedBlog from "./Containers/Blog/DeletedList";

import CommentList from "./Containers/Comment/List";
import CommentAdd from "./Containers/Comment/Add";
import CommentUpdate from "./Containers/Comment/Update";
import DeletedComment from "./Containers/Comment/DeletedList";

import PackageList from "./Containers/Package/List";
import PackageAdd from "./Containers/Package/Add";
import PackageUpdate from "./Containers/Package/Update";
import DeletedPackage from "./Containers/Package/DeletedList";

import TripinfoList from "./Containers/Tripinfo/List";
import TripinfoAdd from "./Containers/Tripinfo/Add";
import TripinfoUpdate from "./Containers/Tripinfo/Update";
import DeletedTripinfo from "./Containers/Tripinfo/DeletedList";

import MediaList from "./Containers/Media/List";
import MediaAdd from "./Containers/Media/Add";

import BookingList from "./Containers/Booking/List";
import InquiryList from "./Containers/Booking/InquiryList";

import Redirection from "./Containers/Redirection";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route path="/" index element={<Dashboard />} />
          <Route path="/users" element={<User />} />
          <Route path="/user/add" element={<UserAdd />} />
          <Route path="/user/update/:id" element={<UserUpdate />} />

          <Route path="/settings" element={<SettingsList />} />
          <Route path="/setting/add" element={<SettingAdd />} />
          <Route path="/setting/update/:id" element={<SettingUpdate />} />

          <Route path="/categories" element={<CategoryList />} />
          <Route path="/category/add" element={<CategoryAdd />} />
          <Route path="/category/update/:id" element={<CategoryUpdate />} />
          <Route path="/category-types" element={<CategoryType />} />
          <Route path="/category-type/add" element={<CategoryTypeAdd />} />
          <Route path="/category/deleted" element={<DeletedCategory />} />
          <Route
            path="/category-type/deleted"
            element={<DeletedCategoryType />}
          />
          <Route
            path="/category-type/update/:id"
            element={<CategoryTypeUpdate />}
          />
          <Route path="/album/add" element={<AlbumAdd />} />
          <Route path="/albums" element={<AlbumList />} />
          <Route path="/album/update/:id" element={<AlbumUpdate />} />
          <Route path="/album/deleted" element={<DeletedAlbum />} />

          <Route path="/articles" element={<PageList />} />
          <Route path="/article/add" element={<PageAdd />} />
          <Route path="/article/update/:id" element={<PageUpdate />} />
          <Route path="/article/deleted" element={<DeletedPage />} />

          <Route path="/menus" element={<MenuList />} />
          <Route path="/menu/add" element={<MenuAdd />} />
          <Route path="/menu/update/:id" element={<MenuUpdate />} />
          <Route path="/menu/deleted" element={<DeletedMenu />} />
          <Route path="/menu/order" element={<MenuOrder />} />

          <Route path="/manage-menu" element={<MenuTypeList />} />
          <Route path="/menu-type/add" element={<MenuTypeAdd />} />
          <Route path="/menu-type/update/:id" element={<MenuTypeUpdate />} />
          <Route path="/menu-type/deleted" element={<DeletedMenuType />} />

          <Route path="/teams" element={<TeamList />} />
          <Route path="/team/update/:id" element={<TeamUpdate />} />
          <Route path="/team/add" element={<TeamAdd />} />
          <Route path="/team/deleted" element={<DeletedTeam />} />

          <Route path="/testimonials" element={<TestimonialList />} />
          <Route path="/testimonial/add" element={<TestimonialAdd />} />
          <Route
            path="/testimonial/update/:id"
            element={<TestimonialUpdate />}
          />
          <Route path="/testimonial/deleted" element={<DeletedTestimonial />} />
          <Route path="/faqs" element={<FaqsList />} />
          <Route path="/faq/add" element={<FaqsAdd />} />
          <Route path="/faq/update/:id" element={<FaqsUpdate />} />
          <Route path="/faq/deleted" element={<DeletedFaqs />} />

          <Route path="/blog/authors" element={<AuthorList />} />
          <Route path="/blog/author/add" element={<AuthorAdd />} />
          <Route path="/blog/author/update/:id" element={<AuthorUpdate />} />
          <Route path="/blog/author/deleted" element={<DeletedAuthor />} />

          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blog/add" element={<BlogAdd />} />
          <Route path="/blog/update/:id" element={<BlogUpdate />} />
          <Route path="/blog/deleted" element={<DeletedBlog />} />

          <Route path="/blog/comments" element={<CommentList />} />
          <Route path="/blog/comment/add" element={<CommentAdd />} />
          <Route path="/blog/comment/update/:id" element={<CommentUpdate />} />
          <Route path="/blog/comment/deleted" element={<DeletedComment />} />

          <Route path="/packages" element={<PackageList />} />
          <Route path="/package/add" element={<PackageAdd />} />
          <Route path="/package/update/:id" element={<PackageUpdate />} />
          <Route path="/package/deleted" element={<DeletedPackage />} />

          <Route path="/tripinfos" element={<TripinfoList />} />
          <Route path="/tripinfo/add" element={<TripinfoAdd />} />
          <Route path="/tripinfo/update/:id" element={<TripinfoUpdate />} />
          <Route path="/tripinfo/deleted" element={<DeletedTripinfo />} />


          <Route path="/allmedia" element={<MediaList />} />
          <Route path="/media/add" element={<MediaAdd />} />

          <Route path="/bookings" element={<BookingList />} />
          <Route path="/inquiries" element={<InquiryList />} />

          <Route path="/redirection" element={<Redirection />} />

        </Route>
      </Route>
      <Route path="*" element={<h1>Page Not found</h1>} />
    </Routes>
  );
}

export default App;
