import React from "react";

export default function KeySearch() {
  return (
    <form className="key-search">
      <input
        type="text"
        className="form-control"
        placeholder="Search Your Query"
        required
        autoComplete="off"
      />
      <button type="submit" className="btn">
        <i className="bi bi-search"></i>
      </button>
    </form>
  );
}
