import React, { useEffect } from "react";
import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import AlbumForm from "../../Components/Forms/AlbumForm";
import { useCreateAlbumMutation } from "../../redux/slice/album/albumApiSlice";
import { message } from "antd";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  const [
    createEntity,
    {
      data: createdAlbumData,
      isLoading: creatingAlbum,
      isError: createAlbumError,
      isSuccess: albumCreated,
      error,
    },
  ] = useCreateAlbumMutation("createAlbum");

  let data = {
    data: undefined,
    loading: creatingAlbum,
    error: createAlbumError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingAlbum,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateMessage(error, albumCreated, "Ablum");

  return (
    <DashboardAdd>
      <AlbumForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
