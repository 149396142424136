import CategoryTypeForm from "../../Components/Forms/CategoryTypeForm";
import { useParams } from "react-router";
import {
  useCreateCategoryTypeMutation,
  useGetCategoryTypeByIdQuery,
  useUpdateCategoryTypeMutation,
} from "../../redux/slice/category/categoryTypeApiSlice";
import { useEffect } from "react";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import useHandleUpdateMessage from "../../hooks/useHandleUpdateMessage"

function CategoryTypeUpdate() {
  let params = useParams();

  const {
    data: categories,
    isLoading: loadingById,
    isSuccess: successById,
    refetch,
  } = useGetCategoryTypeByIdQuery(params.id, {});

  useEffect(() => {
    refetch();
  }, []);

  let [
    updateEntityInfo,
    {
      isLoading: updating,
      error: updateEntityError,
      isSuccess: categoryTypeUpdated,
    },
  ] = useUpdateCategoryTypeMutation("update");

  let data = {
    data: undefined,
    loading: updating || loadingById,
    error: false,
    dataById: successById && categories,
  };

  useHandleUpdateMessage(
    updateEntityError,
    categoryTypeUpdated,
    "Category Type details"
  );

  return (
    <DashboardUpdate>
      <CategoryTypeForm isUpdateForm {...{ params, updateEntityInfo, data }} />
    </DashboardUpdate>
    // <DashboardAdd>
    //   <CategoryTypeForm
    //     isUpdateForm
    //     {...{
    //       params,
    //       getEntityById,
    //       createEntity,
    //       updateEntityInfo,
    //       data,
    //       selectedForm,
    //     }}
    //   />
    // </DashboardAdd>
  );
}

export default CategoryTypeUpdate;
