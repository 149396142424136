import React, { useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  TreeSelect,
  Alert,
  Upload,
  Select,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";
import { BASE_URL } from "../../lib/urls";
import { useGetAlbumListQuery } from "../../redux/slice/album/albumApiSlice";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const MediaForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  const navigate = useNavigate();
  const [bannerImage, setBannerImage] = useState();

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const { data: albumList, isLoading: albumListLoading } =
    useGetAlbumListQuery();

  const loading = selectedForm.loading || data.loading || albumListLoading;

  const albumListOptions = albumList?.map((itm) => {
    return {
      value: itm.id,
      label: itm.title,
    };
  });

  const clearForm = () => {
    form.resetFields([
      "image_file",
      "alt_text",
      "caption",
      "content_url",
      "short_description",
    ]);
    //form.setFieldValue("carousel_id", )
  };

  if (isUpdateForm) {
    setFieldsValue({
      carousel_id: data.dataById?.carousel_id,
      image_file: data.dataById?.full_path,
      alt_text: data.dataById?.alt_text,
      caption: data.dataById?.caption,
      content_url: data.dataById?.content_url,
      parent_id: data.dataById?.parent_id,
      short_description: data.dataById?.short_description,
      status: data.dataById?.status === 1 ? true : false,
    });
  }
  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      console.log(values);
      if (action === "add") {
        createEntity({
          ...values,
          status: values.status ? 1 : 0,
          ...(bannerImage && { image_file: bannerImage.file }),
        });
      } else if (action === "addandexit") {
        createEntity({
          ...values,
          status: values.status ? 1 : 0,
          ...(bannerImage && { image_file: bannerImage.file }),
        })
          .unwrap()
          .then(() => navigate("/allmedia"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...values,
            status: values.status ? 1 : 0,
            ...(bannerImage && { image_file: bannerImage.file }),
          },
          id: params.id,
        })
          .unwrap()
          .then()
          .catch();
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const actionBarOptions = {
    titleToAdd: "Add Media",
    titleToUpdate: "Update Media",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Media",
    taskCompleteAddMessage: "Media is added!",
    taskCompleteUpdateMessage: "Media is updated!",
    discardLink: "allmedia",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div
            className="common-module mt-3 bg-white"
            style={{ maxWidth: 700 }}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label="Album"
                  name="carousel_id"
                  rules={[
                    {
                      required: true,
                      message: "Album is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                  >
                    {albumListOptions?.map((parent) => {
                      const { value, label } = parent;
                      return (
                        <Option key={value} value={value}>
                          {label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24}>
                <Form.Item name="bannerImage" style={{ marginBottom: "0" }}>
                  <Upload
                    listType="picture-card"
                    maxCount={1}
                    name="tet"
                    beforeUpload={(file) => {
                      return false;
                    }}
                    onChange={(file) => setBannerImage(file)}
                  >
                    {isUpdateForm &&
                    data.dataById?.full_path &&
                    !bannerImage ? (
                      <img src={BASE_URL + data.dataById?.full_path} />
                    ) : (
                      <UploadOutlined style={{ fontSize: "27px" }} />
                    )}
                  </Upload>
                  <span className="text-muted">Max Size 2 MB</span>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24}>
                <Form.Item label="Alt Text" name="alt_text">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label="Caption"
                  name="caption"
                  rules={[
                    {
                      required: true,
                      message: "caption is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24}>
                <Form.Item label="Short Description" name="short_description">
                  <TextArea />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24}>
                <Form.Item
                  label="Video Url [Optional]"
                  name="content_url"
                  rules={[
                    {
                      type: "url",
                      warningOnly: true,
                      message: "Video url is not a valid url",
                    },
                    { type: "string", min: 6 },
                  ]}
                >
                  <Input type="url" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default MediaForm;
