import { message } from "antd";
import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const menuApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Menu", "MenuById", "DetetedMenu"],
  endpoints: (builder) => ({
    getMenuList: createQueryForList(builder, "/menu/active", ["Menu"]),

    getMenuOptions: builder.query({
      query: () => "/menu/options",
      providesTags: ["MenuOption"],
      transformResponse: (response) => response.data,
    }),

    getMenuById: createQueryById(builder, "/menu/active", ["MenuType"]),

    getDeletedMenuList: createQueryForList(builder, "/menu/deleted", ["DetetedMenu"]),

    deleteMenuById: createDeleteRestoreMutation(builder, "/menu/bulk/delete", ["Menu", "MenuById"]),

    permanentDeleteMenu: createDeleteRestoreMutation(builder, "/menu/bulk/perma-delete", [
      "DetetedMenu",
    ]),
    restoreDeletedMenu: createDeleteRestoreMutation(builder, "/menu/bulk/restore", ["DetetedMenu"]),

    sortMenuById: builder.mutation({
      query: (idList) => {
        const webKitFormData = new FormData();
        if (idList.item_array) {
          idList.item_array.forEach((item) => {
            webKitFormData.append("item_array[]", item);
          });
          delete idList.item_array;
        }
        Object.keys(idList).forEach((item) => {
          webKitFormData.append(item, idList[item]);
        });
        return {
          url: "/menu/order",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Menu"],
    }),

    createMenu: builder.mutation({
      query: (formData) => {
        const webKitFormData = new FormData();
        Object.keys(formData).forEach((item) => {
          webKitFormData.append(item, formData[item]);
        });

        return {
          url: "/menu/add",
          method: "POST",
          body: webKitFormData,
        };
      },
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        const addResult = dispatch(
          menuApiSlice.util.updateQueryData("getMenuList", undefined, (draftMenus) => {
            draftMenus.push(formData);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          addResult.undo();
          console.error("Error on adding the data:", error);
          message.error("Error on adding the data");
        }
      },
      invalidatesTags: [{ type: "Menu", id: "LIST" }],
    }),

    updateMenu: builder.mutation({
      query: ({ formData, id }) => {
        const webKitFormData = new FormData();
        Object.keys(formData).forEach((item) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/menu/edit/${id}`,
          method: "POST",
          body: webKitFormData,
        };
      },
      async onQueryStarted({ id, formData }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          menuApiSlice.util.updateQueryData("getMenuById", id, (draft) => {
            Object.assign(draft, formData);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          console.error("Error on updating the data:", error);
          message.error("Error on updating the data");
        }
      },

      invalidatesTags: ["Menu"],
      // invalidatesTags: (result, error, arg) => {
      //   console.log("argInvalidates", [
      //     { id: `id-${arg.id}`, type: ["MenuType"] },
      //   ]);
      //   return [{ id: `id-${arg.id}`, type: ["MenuType"] }];
      // },
    }),
  }),
});

export const {
  useGetMenuListQuery,
  useGetMenuOptionsQuery,
  useGetMenuByIdQuery,
  useGetDeletedMenuListQuery,
  useDeleteMenuByIdMutation,
  usePermanentDeleteMenuMutation,
  useRestoreDeletedMenuMutation,
  useSortMenuByIdMutation,
  useCreateMenuMutation,
  useUpdateMenuMutation,
} = menuApiSlice;
