import { apiSlice } from "../api/apiSlice"

export const mediaAPISlice = apiSlice.injectEndpoints({
  tagTypes: ["Media", "MediaById", "DetetedMedia", "MediaByDirectory"],
  endpoints: (builder) => ({
    fetchAllMedia: builder.query({
      query: () => "media/all",
      transformResponse: (response) => response.data.sort((a, b) => b.id - a.id),
      providesTags: ["Media"],
    }),
    fetchMediaWithPagination: builder.query({
      query: ({ limit, start }) => `media/fetch?_limit=${limit}&_start=${start}`,
      transformResponse: (response) => response.data,
    }),

    fetchMediaDirectoryList: builder.query({
      query: () => "media/directories",
      transformResponse: (response) => response.data,
      providesTags: ["MediaDirectory"],
    }),

    getMediaByDirectory: builder.query({
      query: ({ directoryName, search, limit, start }) =>
        `media/directory/${directoryName}?_query=${search}&_limit=${limit}&_start=${start}`,
      providesTags: (result, error, args) => [
        {
          type: "MediaByDirectory",
          id: args.directoryName,
        },
        "MediaByDirectory",
      ],
    }),

    addMedia: builder.mutation({
      query: (mediaFiles) => {
        let webKitFormData = new FormData()

        mediaFiles.forEach((media, index) => {
          Object.entries(media).forEach(([key, value]) => {
            webKitFormData.append(`media_files[${index}][${key}]`, value)
          })
        })
        return {
          url: "media/add",
          method: "POST",
          body: webKitFormData,
        }
      },
      invalidatesTags: ["Media"],
    }),

    editMedia: builder.mutation({
      query: ({ formData, id }) => {
        let webKitFormData = new FormData()
        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item])
        })
        webKitFormData.append("_method", "put")

        return {
          url: `media/edit/${id}`,
          method: "POST",
          body: webKitFormData,
        }
      },
    }),

    bulkDeleteMedia: builder.mutation({
      query: (mediaIds) => ({
        url: "media/bulk/delete",
        method: "POST",
        body: { bulk: mediaIds },
      }),
    }),
  }),
})

export const {
  useFetchAllMediaQuery,
  useFetchMediaWithPaginationQuery,
  useFetchMediaDirectoryListQuery,
  useGetMediaByDirectoryQuery,
  useAddMediaMutation,
  useEditMediaMutation,
  useBulkDeleteMediaMutation,
} = mediaAPISlice
